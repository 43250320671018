<template>
    <DetailItemEditCard v-bind="$attrs" v-on="$listeners" :item-name="getUserName" :promises.sync="detailItemEditCardPromises"
        :formPromises.sync="computedPromises">
        <template v-slot:form-fields>
            <slot name="form-fields"/>
        </template>
      <template v-slot:buttons-left="slotProps">
        <slot name="buttons-left" v-bind="slotProps"/>
      </template>
      <template v-slot:save-button-content>
        <slot name="save-button-content"/>
      </template>
    </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"

export default {
    name: "UserDetailItemEditCard",
    props: {
        userId: {
            type: String,
            required: true
        },
        promises: {
            default: () => []
        }
    },
    emits: ["update:promises"],
    data() {
        return {
            detailItemEditCardPromises: [this.$store.dispatch('usersModule/fetchBasicUser', this.userId)]
        }
    },
    inheritAttrs: false,
    components: {
        DetailItemEditCard,
    },
    computed: {
        getUserName() {
            const user = this.$store.state.usersModule.basicUser
            return user.firstName + ' ' + user.lastName
        },
        computedPromises: {
            get() {
                return this.promises
            },
            set(newValue) {
                this.$emit("update:promises", newValue)
            }
        } 
    },
}

</script>