export const ciaoStatuses = Object.freeze({
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
    OUT_OF_SYNC: 'OUT_OF_SYNC'
})

export const order = [
    ciaoStatuses.FAILED,
    ciaoStatuses.OUT_OF_SYNC,
    ciaoStatuses.SUCCEEDED
]