import {traceError} from "@/tracing";
import DaysOfWeek from "../constants/DaysOfWeek";
import Parameters from "../constants/Parameters";
import InvoiceStatusType from "@/shared/enums/invoiceStatusType";
import {ciaoStatuses} from '@/shared/constants/CiaoStatuses';
import PacketType from "@/shared/enums/packetType";
import ModuleType from "@/shared/enums/moduleType";
import Clockingtype from "@/shared/enums/clockingType";

export function translatePacketName(packetType) {
    switch (packetType) {
        case PacketType.NONE:
            return "Geen pakket"
        case PacketType.TIME_BASE:
            return "Time Base"
        case PacketType.LOCATE_BASE:
            return "Locate Base"
        case PacketType.SALES_BASE:
            return "Sales Base"
        case PacketType.SALESTIME_BASE:
            return "SalesTime Base"
        case PacketType.ALL:
            return "All-in"
        default:
            return translationUnavailable(packetType)
    }
}

export function translateModuleName(moduleType) {
    switch (moduleType) {
        case ModuleType.TIME_REGISTRATION:
            return "Tijdsregistratie"
        case ModuleType.EMPLOYEE_MANAGEMENT:
            return "Personeelsbeheer"
        case ModuleType.CLIENT_PROJECTMANAGEMENT:
            return "Klant & project database"
        case ModuleType.INVOICE:
            return "Facturatie"
        case ModuleType.LOCATION_REGISTRATION:
            return "Locatieregistratie"
        case ModuleType.PLANNING:
            return "Planning"
        default:
            return translationUnavailable(moduleType)
    }
}


export function translateSubscriptionType(type) {
    switch (type) {
        case "MONTHLY":
            return "Maandelijks"
        case "YEARLY":
            return "Jaarlijks"
        case "LIFETIME":
            return "Levenslang"
        default:
            return translationUnavailable(type)
    }
}

export function translateClockingType(clockingType) {
    switch (clockingType) {
        case Clockingtype.WORK:
            return "Werk"
        default:
            return translationUnavailable(clockingType)
    }
}

export function translateDay(day) {
    switch (day) {
        case DaysOfWeek.MONDAY:
            return "Maandag"
        case DaysOfWeek.TUESDAY:
            return "Dinsdag"
        case DaysOfWeek.WEDNESDAY:
            return "Woensdag"
        case DaysOfWeek.THURSDAY:
            return "Donderdag"
        case DaysOfWeek.FRIDAY:
            return "Vrijdag"
        case DaysOfWeek.SATURDAY:
            return "Zaterdag"
        case DaysOfWeek.SUNDAY:
            return "Zondag"
        default:
            return translationUnavailable(day)
    }
}

export function translateDayAbbreviation(day) {
    return translateDay(day).substring(0, 2)
}

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/nl.json'))
export function translateNationalitiy(nationality) {
    return countries.getName(nationality, "nl")
}

export function translateGender(gender) {
    switch (gender) {
        case "MALE":
            return "Man"
        case "FEMALE":
            return "Vrouw"
        case "OTHER":
            return "Andere"
        default:
            return translationUnavailable(gender)
    }
}

export function translateCivilStatus(civilStatus) {
    switch (civilStatus) {
        case "DIVORCED":
            return "Gescheiden"
        case "LEGALLY_COHABITING":
            return "Wettelijk samenwonend"
        case "MARRIED":
            return "Getrouwd"
        case "SINGLE":
            return "Alleenstaand"
        case "WIDOW":
            return "Weduwenaar"
        case "OTHER":
            return "Andere"
        default:
            return translationUnavailable(civilStatus)
    }
}

export function translateDependents(dependents) {
    if (dependents != null) {
        if (dependents > 1) {
            return `${dependents} personen ten laste`
        } else if (dependents == 1) {
            return "1 persoon ten laste"
        } else if (dependents >= 0) {
            return "Geen personen ten laste"
        }
    }

    return translationUnavailable(dependents)
}

export function translateRole(roleName) {
    switch (roleName) {
        case 'ROLE_ADMIN':
            return 'Beheerder'
        case 'ROLE_USER':
            return 'Gebruiker'
        case 'ROLE_LEADER':
            return 'Teamleider'
        case 'ROLE_ADMIN_TOOL_DATA':
            return 'Admin tool data beheerder'
        case 'ROLE_ADMIN_TOOL_RELEASE':
            return 'Admin tool setup release'
        default:
            return translationUnavailable(roleName)
    }
}

export function translateParameterName(parameterName) {
    switch (parameterName) {
        case Parameters.MaxDaysClockingInThePast:
            return 'Maximum aantal dagen in het verleden voor werkuren'
        case Parameters.MaxDaysAbsenceInThePast:
            return 'Maximum aantal dagen in het verleden voor afwezigheden'
        case Parameters.EnableContiguousHours:
            return 'Werkuren moeten aansluitend zijn'
        case Parameters.DateFormat:
            return 'Formaat van datums'
        case Parameters.EnableTeamsOption:
            return 'Teams functionaliteit aanzetten'
        case Parameters.OrgStartTime:
            return 'Begin werkdag'
        case Parameters.EnableCIAO:
            return 'Check In and Out at Work (CIaO) integratie aanzetten'
        default:
            return translationUnavailable(parameterName)
    }
}

export function translateParameterNameMobile(parameterName) {
    switch (parameterName) {
        case Parameters.MaxDaysClockingInThePast:
            return 'Max dagen werkuren'
        case Parameters.MaxDaysAbsenceInThePast:
            return 'Max dagen afwezig'
        case Parameters.EnableContiguousHours:
            return 'Werkuren aansluitend'
        case Parameters.DateFormat:
            return 'Formaat van datums'
        case Parameters.EnableTeamsOption:
            return 'Teams functionaliteit'
        case Parameters.OrgStartTime:
            return 'Begin werkdag'
        case Parameters.EnableCIAO:
            return 'CIaO integratie'
        default:
            return translationUnavailable(parameterName)
    }
}

export function getParameterDescriptionByName(parameterName) {
    switch (parameterName) {
        case Parameters.MaxDaysClockingInThePast:
            return 'Maximum aantal dagen in het verleden dat een gebruiker werkuren kan registreren'
        case Parameters.MaxDaysAbsenceInThePast:
            return 'Maximum aantal dagen in het verleden dat een gebruiker afwezigheden kan registreren'
        case Parameters.EnableContiguousHours:
            return 'Werkuren moeten aansluitend zijn'
        case Parameters.DateFormat:
            return 'De manier waarop datums worden weergegeven'
        case Parameters.EnableTeamsOption:
            return 'Het aanzetten van de teams functionaliteit'
        case Parameters.OrgStartTime:
            return 'Het standaard uur waarop een werkdag begint'
        case Parameters.EnableCIAO:
            return 'Het aanzetten van Check In and Out at Work (CIaO) integratie'
        default:
            return translationUnavailable(parameterName)
    }
}

export function translateInvoiceStatusType(invoiceStatusType) {
    switch (invoiceStatusType) {
        case InvoiceStatusType.DRAFT:
            return "Ontwerp"
        case InvoiceStatusType.OPEN:
            return "Open"
        case InvoiceStatusType.PAID:
            return "Betaald"
        default:
            return translationUnavailable(invoiceStatusType)
    }
}

export function getInvoiceStatusTypeColor(invoiceStatusType) {
    switch (invoiceStatusType) {
        case InvoiceStatusType.DRAFT:
            return "banner-orange"
        case InvoiceStatusType.OPEN:
            return "banner-blue"
        case InvoiceStatusType.PAID:
            return 'green'
        default:
            return translationUnavailable(invoiceStatusType)
    }
}

export function translateCiaoStatus(ciaoStatus) {
    switch (ciaoStatus) {
        case ciaoStatuses.FAILED:
            return "Gefaald"
        case ciaoStatuses.SUCCEEDED:
            return "Succes"
        case ciaoStatuses.OUT_OF_SYNC:
            return 'Uit sync'
        default:
            return translationUnavailable(ciaoStatus)
    }
}

export function getCiaoStatusDescription(ciaoStatus, ciaoType) {
    const ciaoTypeString = `${ciaoType ? ciaoType + ' ' : ''}`

    switch (ciaoStatus) {
        case ciaoStatuses.FAILED:
            return `De CiAO ${ciaoTypeString}registratie is niet gelukt.`
        case ciaoStatuses.SUCCEEDED:
            return `De CiAO ${ciaoTypeString}registratie is succesvol uitgevoerd.`
        case ciaoStatuses.OUT_OF_SYNC:
            return `De CiAO ${ciaoTypeString}registratie komt niet overeen met de Buildbase registratie.`
        default:
            return translationUnavailable(ciaoStatus)
    }
}

function translationUnavailable(value) {
    if (value) {
        traceError(new Error(`Translation unavailable for: ${value}`), "translation error")
    }
    return value
}