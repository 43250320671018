<template>
    <v-autocomplete v-bind="$attrs" v-on="$listeners" :items="countries" item-text="label" item-value="value">
        <template v-slot:item="{ item }">
            <v-row>
                <v-col class="flex-shrink-1 flex-grow-0">
                    <country-flag  class="align-self-start" :country="item.value" size="normal" rounded/>
                </v-col>
                <v-col class="flex-grow-1 align-self-center">
                    {{ item.label }}
                </v-col>
            </v-row>
        </template>
    </v-autocomplete>
</template>
<script>
import CountryFlag from 'vue-country-flag'

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/nl.json'))

export default {
    name: 'CountryCodeSelect',
    inheritAttrs: false,
    data() {
        return {
            commonCountryCodes: ['BE', 'NL']
        }
    },
    components: {
        CountryFlag
    },
    computed: {
        countries () {
            const countriesList = countries.getNames('nl', { select: 'official' })
            let mappedCountries = Object.keys(countriesList).map((key) => ({ value: key, label: countriesList[key] }))
            mappedCountries.sort((a, b) => {
                const aIsCommon = this.commonCountryCodes.includes(a.value);
                const bIsCommon = this.commonCountryCodes.includes(b.value);
                if (aIsCommon == bIsCommon) {
                    return (a.label > b.label) ? 1 : -1
                } else {
                    return bIsCommon ? 1 : -1
                }
            })
            return mappedCountries;
        },
    }
}
</script>